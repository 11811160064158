import { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";

export default function LogoutPage() {

  let navigate = useNavigate()

  useEffect(() => {
    navigate("/login")
    localStorage.removeItem("token")
  },[])

  return (
    <>
      Logging Out
    </>
  );
}
