import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs'
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import moment from "moment";
import USERLIST from '../_mock/user';
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import CustomTable from "../components/reusable/CustomTable"
import UserTagModalV3 from 'src/components/custom/UserTagModalV3';
import UserTagModal from "../components/custom/UserTagModal";
import { Tooltip } from 'antd';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState([]);

  const [users, setUsers] = useState([]);

  const [isNewRecord, setIsNewRecord] = useState(true)
  const [formMode, setFormMode] = useState(null);

  const [qrData, setQRData] = useState(null)
  const [qrDataForThermal, setQRDataForThermal] = useState(null)

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setData(row)
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setData(null);
  };

  const deleteEntry = async () => {
    try {
      let res = await User.delete(data.id)
      console.log(res)
      if (res.success) {
        fetchUsers()
        handleCloseMenu()
        return
      }
      throw (res)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchUsers = async () => {
    try {
      let res = await User.getAll();
      let formattedRes = res.map(obj => {
        return {
          ...obj,
          uppercaseName: obj?.name?.toUpperCase()
        }
      })
      setUsers(formattedRes)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [])

  const columns = [
    {
      id: 'id', label: 'ID', filter: true,
    },
    {
      id: 'uppercaseName', label: 'Name', filter: true,
      render: row => {
        return <>
          <b>{row.name}</b>
        </>
      }
    },
    {
      id: 'dob', label: 'DoB', render: (row) => {
        return row.dob ? moment(row.dob).format("DD MMM YYYY") : "-"
      }
    },
    {
      id: 'gender', label: 'Gender', filter: true,
    },
    {
      id: 'email', label: 'Email', filter: true,
    },
    {
      id: 'phone_num', label: 'Phone', filter: true,
    },
    {
      id: 'checked_in', label: 'Scanned',
      render: row => {
        return <>
          <b>{row.checked_in ? "Yes" : "No"}</b>
        </>
      }
    },
    {
      id: 'actions', label: "", render: (row, key) => {
        return <>
          <Tooltip title="Print Nametag">
            <IconButton
              onClick={() => {
                setQRData({
                  name: row.name,
                  title: "PESERTA",
                  code: row.uuid
                })
              }}
              size="large" color="inherit">
              <Iconify icon={'eva:printer-fill'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print Thermal">
            <IconButton
              onClick={() => {
                setQRDataForThermal({
                  name: row.name,
                  title: "PESERTA",
                  code: row.uuid
                })
              }}
              size="large" color="inherit">
              <Iconify icon={'openmoji:barcode'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton size="large" color="inherit" onClick={(event) => {
              setData(row);
              setOpenModal(true);
              setFormMode('edit')
            }}>
              <Iconify icon={'eva:edit-fill'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </>
      }
    },
  ];

  return (
    <>
      <Helmet>
        <title> User | Intellivent Registration Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button style={{ backgroundColor: palette.ColorB }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
            setIsNewRecord(true)
            setOpenModal(true)
          }}>
            New User
          </Button>
        </Stack>

        <Card>
          <CustomTable
            data={users}
            columns={columns}
            searchText="users"
          />
        </Card>

      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={() => setOpenModal(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
        <Typography>Are you sure you want to delete this entry?</Typography>
        <div className='d-flex justify-content-between'>
          <Button onClick={handleCloseMenu}>No</Button>
          <Button onClick={deleteEntry}>Yes</Button>
        </div>
      </Popover>

      <UserModal
        mode={formMode}
        open={openModal}
        isNewRecord={isNewRecord}
        onClose={(isRefresh) => {
          setOpenModal(false);
          setData(null);
          setIsNewRecord(true)
          if (isRefresh) fetchUsers();
        }}
        data={data}
      />

      <UserTagModal
        data={qrData}
        onClose={() => {
          setQRData(null)
        }}
      />

      <UserTagModalV3
        data={qrDataForThermal}
        onClose={()=>{
          setQRDataForThermal(null)
        }}
      />

    </>
  );
}
