import {

    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, message, Radio } from 'antd';
import { da } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Admin from 'src/api/admin';
import { Form, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const AdminModal = (props) => {
    const { open, data, onClose, isNewRecord } = props;
    // console.log('hubla', data)
    const [id, setId] = useState();
    const [avatarUrl, setAvatarUrl] = useState();
    const [username, setUsername] = useState();
    const [role, setRole] = useState(null);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const clearData = () => {
        setUsername()
        setAvatarUrl(null)
        setPassword()
        setConfirmPassword()
        setRole()
    }

    const handleClose = (isRefresh = true) => {
        clearData()
        onClose(isRefresh)
    }

    useEffect(() => {
        if (open) {
            setId(data?.id);
            setAvatarUrl(data?.avatarUrl);
            setUsername(data?.username);
            setRole(data?.role);
        }
    }, [data])

    const submit = async () => {

        if (!username) {
            message.error('Field username is required')
            return
        }
        if (!role) {
            message.error('Field role is required')
            return
        }

        if (confirmPassword !== password) {
            message.error('Password and Confirm Password doesnt match')
            return
        }
        let body = {
            username: username,
            role: role,
            avatarUrl: avatarUrl,
            password: password,
        }
        // console.log(body)
        if (!data) {
            let res = await Admin.create(body);
            handleClose(true);
            return;
        }
        let res = await Admin.edit(id, body);

        handleClose(true);


        return;

    }

    return (
        <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} Admin</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="new-password">

                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Username</Form.Label>
                        <Form.Control
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            placeholder="Please enter username"
                            value={username}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Role</Form.Label>
                        <Radio.Group value={role} style={{ width: '100%' }} onChange={(e) => {
                            setRole(e.target.value);
                        }}>
                            <Radio value={"SUPERADMIN"}>SUPERADMIN</Radio>
                            <Radio value={"ADMIN"}>ADMIN</Radio>
                        </Radio.Group>
                    </Form.Group>

                    {
                        isNewRecord &&
                        <>
                            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                                <Form.Label style={{ fontSize: "0.8em" }}>Password</Form.Label>
                                <Form.Control
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    placeholder="Please enter Password"
                                    value={password}
                                />
                            </Form.Group>

                            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                                <Form.Label style={{ fontSize: "0.8em" }}>Confirm Password</Form.Label>
                                <Form.Control
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    type="password"
                                    placeholder="Please enter Confirm Password"
                                    value={confirmPassword}
                                />
                            </Form.Group>
                        </>

                    }

                    <div className={"d-flex flex-row justify-content-end"}>
                        <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton className='ml-3' size="sm" onClick={submit} variant="primary">
                            {isNewRecord ? 'Create' : 'Save'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default AdminModal;