// component
import SvgColor from '../../../components/svg-color';
import { Icon } from '@iconify/react';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'admin',
    path: '/admin',
    icon: <Icon icon="eos-icons:admin" />,
  },
  {
    title: 'booth',
    path: '/booth',
    icon: <Icon icon="icon-park-solid:booth" />,
  },
  {
    title: 'user',
    path: '/user',
    icon: <Icon icon="mdi:user-multiple" />,
  },
  {
    title: 'QR Scanner',
    path: '/qr',
    icon: <Icon icon="ic:baseline-qr-code" />,
  },
  {
    title: 'QR Kiosk Demo',
    path: '/qr-kiosk-demo',
    icon: <Icon icon="ic:baseline-qr-code" />,
  },
  {
    title: 'QR IR Scanner',
    path: '/qr-ir-scanner',
    icon: <Icon icon="ic:baseline-qr-code" />,
  },
  {
    title: 'Input QR Manual',
    path: '/input-qr',
    icon: <Icon icon="ic:baseline-qr-code" />,
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: <Icon icon="ri:logout-circle-r-line" />,
  },

];

export default navConfig;
