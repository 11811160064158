import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  email:faker.internet.email(),
  gender: faker.name.gender(true),
  dob: faker.date.birthdate(),
  phone: faker.phone.phoneNumber('08##########')
}));

export default users;
