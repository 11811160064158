import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  DialogContent,
  DialogActions,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import moment from "moment";
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import { QrReader } from 'react-qr-reader';
import { message } from 'antd';
import dayjs from 'dayjs'
export default function QRScannerFull() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState(null);

  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false)
  const NOTFOUND = 0,
    SUCCESS = 1,
    ERROR = -1

  const FC_USER_MODE = 'user',
    FC_ENVIRONTMENT = 'environment'

  const [scanStatus, setScanStatus] = useState(0)
  const [scanMessage, setScanMessage] = useState(null)
  const [resultScan, setResultScan] = useState()
  const [facingMode, setFacingMode] = useState(FC_USER_MODE)


  const handeMessage = () => {
    let obj = {}
    if (scanStatus === SUCCESS) {
      obj = {
        title: 'Success',
        color: 'green',
        message: 'Checkin successfully'
      }
    } else if (scanStatus === NOTFOUND) {
      obj = {
        title: 'Not Found',
        color: 'yellow',
        message: 'User not found'
      }
    } else {
      obj = {
        title: 'Error',
        color: 'red',
        message: "Invalid QR"
      }
    }
    return obj
  }
  const handleResult = async (qrData) => {
    try {
      let result = await User.getByUUID(qrData)
      console.log('isi resultnya', result)
      if (result?.name) {
        setScanStatus(SUCCESS)
      } else {
        setScanStatus(NOTFOUND)
      }
      setResultScan(result)

    } catch (e) {
      setScanStatus(ERROR)
    }
  }


  const [isFullscreen, setFullscreen] = useState(false)
  const handle = useFullScreenHandle()
  const reportChange = useCallback((state) => {
    console.log(state)
    if (state === true) {
      setFullscreen(true)
    } else if (state === false) {
      setFullscreen(false)
    }
  })


  return (
    <>
  
      <Dialog fullWidth={true} maxWidth={'sm'} onClose={() => {
        setOpenDialog(false)
      }} open={openDialog}>
        <DialogContent dividers>
          <div className='mb-5 text-center'>
            <Iconify sx={{
              color: handeMessage().color,
              width: '80px',
              height: '80px',
            }} icon={'clarity:success-standard-line'} />
            <h3 className='text-center'>{handeMessage().title}</h3>
            <p>{handeMessage().message}</p>
          </div>
          {
            scanStatus === SUCCESS &&

            <div className='info-qr-content'>
              <div className='text-row mb-2'>
                <div className='label-text'>Full Name</div>
                <div className='filled-text'>
                  {resultScan?.name}
                </div>
              </div>

              <div className='text-row mb-2'>
                <div className='label-text'>Date of Birth</div>
                <div className='filled-text'>
                  {dayjs(resultScan?.dob).format('DD/MM/YYYY')}
                </div>
              </div>

              <div className='text-row mb-2'>
                <div className='label-text'>Gender</div>
                <div className='filled-text'>
                  {resultScan?.gender}
                </div>
              </div>
              <div className='text-row mb-2'>
                <div className='label-text'>Email</div>
                <div className='filled-text'>
                  {resultScan?.email}
                </div>
              </div>

              <div className='text-row mb-2'>
                <div className='label-text'>Phone Number</div>
                <div className='filled-text'>
                  {resultScan?.phone_num}
                </div>
              </div>
            </div>
          }



        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            setOpenDialog(false)
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Helmet>
        <title> QR Scanner | Intellivent Registration Dashboard </title>
      </Helmet>
      {/* <Button onClick={() => {setFacingMode(facingMode === FC_USER_MODE ? FC_ENVIRONTMENT : FC_USER_MODE)}}>
          switch
      </Button> */}
      <FullScreen
        handle={handle}
        onChange={reportChange} >
        <QrReader
          videoContainerStyle={{ position: 'unset' }}
          onResult={(result, error) => {
            console.log('hubla', result)
            if (!!result) {
              handleResult(result)
              setOpenDialog(true)
            }

            if (!!error) {


            }

          }}
          ViewFinder={() => {
            return (
              <>
                <div className="crosshair"></div>
              </>
            )
          }}
          constraints={{ facingMode: facingMode }}
        />
      </FullScreen>

      <button onClick={() => {
        handle.enter()
    }}>tesssssss</button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        //onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={() => setOpenModal(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
        <Typography>Are you sure you want to delete this entry?</Typography>
        <div className='d-flex justify-content-between'>
          <Button >No</Button>
          <Button >Yes</Button>
        </div>
      </Popover>

    </>
  );
}
