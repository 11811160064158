import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = "#fdba74";

  const PRIMARY_MAIN = "#ffab14";

  const PRIMARY_DARK = "#ea580c";

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 180,
        height: 80,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="378.000000pt" height="78.000000pt" viewBox="0 0 378.000000 78.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,78.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M160 380 l0 -200 60 0 60 0 0 200 0 200 -60 0 -60 0 0 -200z" />
          <path d="M332 383 l3 -198 58 -3 57 -3 0 121 c0 66 4 120 8 120 4 0 31 -53 58
-117 l51 -118 82 -3 81 -3 0 201 0 200 -60 0 -59 0 -3 -121 -3 -122 -54 122
-54 121 -84 0 -83 0 2 -197z"/>
          <path d="M770 531 l0 -50 43 -3 42 -3 3 -148 3 -148 57 3 57 3 3 148 3 147 39
0 40 0 0 50 0 50 -145 0 -145 0 0 -49z"/>
          <path d="M1100 380 l0 -200 135 0 135 0 0 55 0 55 -75 0 c-68 0 -75 2 -75 20
0 18 7 20 75 20 l75 0 0 50 0 50 -75 0 c-73 0 -75 1 -75 25 0 24 2 25 75 25
l75 0 0 50 0 50 -135 0 -135 0 0 -200z"/>
          <path d="M1430 380 l0 -200 125 0 125 0 0 55 0 55 -65 0 -65 0 0 145 0 145
-60 0 -60 0 0 -200z"/>
          <path d="M1740 380 l0 -200 125 0 125 0 0 55 0 55 -65 0 -65 0 0 145 0 145
-60 0 -60 0 0 -200z"/>
          <path d="M2040 380 l0 -200 60 0 60 0 0 200 0 200 -60 0 -60 0 0 -200z" />
          <path d="M2202 568 c3 -7 34 -98 69 -201 l64 -188 69 3 69 3 68 198 68 197
-68 0 -69 0 -33 -115 c-18 -63 -36 -110 -40 -103 -3 7 -19 59 -35 115 l-29
103 -69 0 c-50 0 -67 -3 -64 -12z"/>
          <path d="M2640 380 l0 -200 135 0 135 0 0 55 0 55 -75 0 c-68 0 -75 2 -75 20
0 18 7 20 75 20 l75 0 0 50 0 50 -76 0 c-72 0 -75 1 -72 23 3 20 9 22 76 25
l72 3 0 49 0 50 -135 0 -135 0 0 -200z"/>
          <path d="M2970 380 l0 -200 60 0 59 0 3 122 3 123 54 -123 54 -122 78 0 79 0
0 200 0 200 -60 0 -60 0 0 -122 0 -123 -55 120 -54 120 -80 3 -81 3 0 -201z"/>
          <path d="M3400 531 l0 -50 43 -3 42 -3 3 -147 3 -148 59 0 60 0 0 150 0 150
40 0 40 0 0 50 0 50 -145 0 -145 0 0 -49z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
