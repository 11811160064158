import {

    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Form, Modal } from "react-bootstrap";
import nameTagTemplate from "../../assets/nametagtemplate.png"
import { useEffect, useRef, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import logo from "../../assets/qrlogo.jpg"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"

import pdfMake, { tableLayouts } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";
import { message } from 'antd';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function UserTagModalDemo({ data, onClose, autoPrint, loading, isStatic }) {

    let formattedData = data ? data : {}
    let { name, title, code } = formattedData

    const [nameTagWidth, setNameTagWidth] = useState(0)
    const [messageApi, contextHolder] = message.useMessage()

    const printContainerRef = useRef(null)

    const handleClose = (isRefresh = true) => {
        onClose(isRefresh)
    }

    const print = () => {

    }

    const generatePDF = async (isPrintMode) => {

        const canvas = await html2canvas(printContainerRef.current, {
            scale: 1.1,
            quality: 4,
            dpi: 320
        });

        let imgWidth = 216;
        const image = canvas.toDataURL("image/jpeg");

        const pdf = new jsPDF('p', 'mm', [105, 148]);
        await pdf.addImage(image, 'JPEG', 0, 0, 105, 148);
        // pdf.output('dataurlnewwindow');

        if (isPrintMode) {
            pdf.autoPrint({ variant: 'non-conform' });
            let output = pdf.output(`bloburl`)

            printJS(output)

            handleClose()

            // console.log("output", output)
            // window.open(output)

        } else {
            await pdf.save(`${name}_NC${moment().format("DDMMYYYYHH:mm")}.pdf`);
        }
    }

    useEffect(() => {
        if (autoPrint && data) {
            generatePDF(true)
            handleClose()
        }
    }, [data])

    return (
        <div style={{
            position: isStatic ? 'absolute' : "fixed",
            alignItems: 'center',
            bottom: 0,
            right: 0,
            top: !!data && !autoPrint ? 0 : -1000,
            left: '50%',
            transform: 'translate(-50%, 0)',
            zIndex: 30,
            height: '30%',
            display: "flex",
            flexDirection: "column",
            transitionDuration: "0.4s"
        }}>
            {contextHolder}
            <div
                style={{
                    width: 250,
                    background: "white",
                    padding: '0.9rem',
                    margin: '2rem'
                }}
                className={"d-flex flex-column"}
                ref={printContainerRef}>
                <div
                    className={"d-flex flex-column align-items-center justify-content-center"}
                    ref={(ref) => {
                        if (ref) {
                            console.log("NTW", ref.clientWidth)
                            setNameTagWidth(ref.clientWidth)
                        }
                    }}
                    style={{
                        // background: `url(${nameTagTemplate})`,
                        backgroundSize: "contain",
                        backgroundRepeatX: "no-repeat",
                        backgroundRepeatY: "no-repeat",
                        width: "100%",
                    }}
                >
                    <div
                        className={"d-flex flex-column align-items-center justify-content-center"}
                        style={{
                            background: "white",
                            width: 0.6 * nameTagWidth,
                            height: 0.8 * nameTagWidth,
                            borderRadius: 0.02 * nameTagWidth
                        }}
                    >
                        <QRCode
                            size={nameTagWidth * 0.52}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={code}
                            viewBox={`0 0 256 256`}
                            ecLevel='H'
                        // logoImage={logo}
                        // logoWidth={60}
                        />
                        {/*<div style={{*/}
                        {/*  width : "100%",*/}
                        {/*  height : 300,*/}
                        {/*  background : `url("https://wellsource.sgp1.digitaloceanspaces.com/intellivent-register/qr/qr_Damocles_1683262576704.png")`*/}
                        {/*}}>*/}

                        {/*</div>*/}
                        {/*<img*/}
                        {/*  style={{*/}
                        {/*    width: "100%",*/}
                        {/*    objectFit: "contain",*/}
                        {/*    borderRadius: 0.02 * nameTagWidth*/}
                        {/*  }}*/}
                        {/*  src={"https://wellsource.sgp1.digitaloceanspaces.com/intellivent-register/qr/qr_Damocles_1683262576704.png"}/>*/}

                        <div style={{
                            fontSize: nameTagWidth * 0.025,
                            fontWeight: 'bolder'
                        }}>
                            {code}
                        </div>

                        <div style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: nameTagWidth * 0.05,
                            marginTop: nameTagWidth * 0.01,
                            textTransform: "uppercase",
                            textAlign: "center",
                            textOverflow: "clip",
                            whiteSpace: "nowrap",
                            width: 0.6 * nameTagWidth,
                            maxWidth: "100%",
                            // overflow : "hidden"
                        }}>
                            {name}
                        </div>
                        <div style={{
                            color: "black",
                            fontSize: nameTagWidth * 0.03,
                            marginTop: nameTagWidth * 0.0001,
                            textTransform: "uppercase"
                        }}>
                            {title ? title : "PESERTA"}
                        </div>

                    </div>
                    <div className={"d-flex flex-row align-items-end justify-content-end"}>
                        <Button className='ml-3 me-2' size="sm" onClick={() => {
                            handleClose()
                        }} variant="outlined">
                            Close
                        </Button>
                        <Button className='mt-2' size="sm" onClick={() => {
                            generatePDF(true)
                            // generatePDFV2()
                        }} variant="contained">
                            Print
                        </Button>
                    </div>
                </div>

            </div>

        </div>

    )
}
