import { message, DatePicker } from "antd";
import { useState } from "react";
import { Button, Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import { QRCode } from 'react-qrcode-logo';
import { createPortal } from 'react-dom';
import User from "src/api/user";
import UserTagModalDemo from "src/components/custom/UserTagModalDemo";

const textfieldStyle = {
  borderTopRightRadius: 1000,
  borderBottomRightRadius: 1000,
  borderTopLeftRadius: 1000,
  borderBottomLeftRadius: 1000,
}

const radiolabelStyle = {
  marginLeft: "0.5em",
  fontSize: "0.8em"
}


export default function InputQR() {
  const [loading, setIsLoading] = useState(null)
  const [code, setCode] = useState("")
  const [qrData, setQRData] = useState(null)
  const [registerMenu, setRegisterMenu] = useState(false);

  const [name, setName] = useState()
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()
  const [dob, setDob] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [gender, setGender] = useState()
  const [messageApi, contextHolder] = message.useMessage();
  const handleClose = (isRefresh = true) => {
    setName()
    setPhone()
    setEmail()
    setPassword()
    setConfirmPassword()
    setDob()
    setGender()
    setRegisterMenu(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      let result = await User.scanAction(code)
      if (result?.name) {
        setQRData({
          name: result.name,
          code: result.uuid,
          title: result.title
        })
        setCode("")
      } else {
        message.error('User not found')
      }
      setIsLoading(false)
    } catch (e) {
      message.error('Failed to get user or User not found.')
      setIsLoading(false)
    }
  }

  return (
    <>
      <UserTagModalDemo
        isStatic={true}
        data={qrData}
        onClose={() => {
          setQRData(null)
        }}
      />
      <div id="fullscreenElement">
        <Container fluid style={{ fontFamily: "Inter" }}>
          <Row>
            <Col md={6} xs={12} sm={12} style={{ background: '#CE93D8', height: '100vh', }}>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <div className="manual-qr-box">
                  <div className="qr-content">
                    <QRCode
                      value={'Abc123'}
                      viewBox={`0 0 256 256`}
                      ecLevel='H'
                    />
                    <div className="booking-code">
                      <div className="tooltip-custom">
                        <span className="tooltiptext">Kode Booking Anda</span>
                      </div>
                      abcd123
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              {!registerMenu ?
                <div style={{
                  display: "flex",
                  maxWidth: "44rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4.5rem",
                }}>
                  <div style={{
                    display: "flex",
                    gap: "2.25rem",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <div style={{
                      fontSize: "2.25rem",
                      fontFamily: "Bitter",
                      fontWeight: 500,
                      lineHeight: "2.75rem",
                    }}>
                      INTELLIVENT
                    </div>
                    <div style={{
                      fontSize: "1.75rem",
                      fontWeight: 500,
                      lineHeight: "2.25rem",
                    }}>
                      Scan QR Code atau masukkan kode booking Anda untuk mencetak tiket
                    </div>
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <Form.Group autoComplete="new-password" className="" controlId="formBasicPassword">
                      <Form.Label style={{ fontSize: "1.25rem", fontWeight: "400", lineHeight: "1.75rem", letterSpacing: "-0.0075rem" }}>Kode Booking</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setCode(e.target.value)
                        }}
                        value={code}
                        autoComplete='off'
                        type="text"
                        placeholder="Masukan Kode Booking Anda"
                      />
                    </Form.Group>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "2rem" }}>
                    <Button onClick={() => {
                      handleSubmit()
                    }}
                      style={{
                        width: "100%",
                        borderRadius: "0.375rem",
                        background: "#000",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                        display: "flex",
                        padding: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.25rem",
                        alignSelf: "stretch",
                      }}>Cetak</Button>
                    <Button style={{
                      width: "100%",
                      borderRadius: "0.375rem",
                      background: "var(--primary-200, #CE93D8)",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                      display: "flex",
                      padding: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.25rem",
                      alignSelf: "stretch",
                    }}
                      onClick={() => setRegisterMenu(true)}
                    >Belum punya akun? Register di sini</Button>
                  </div>
                </div>
                :
                <div style={{
                  display: "flex",
                  maxWidth: "44rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1.5rem",
                }}>
                  <div style={{
                    display: "flex",
                    gap: "2.25rem",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <div style={{
                      fontSize: "2.25rem",
                      fontFamily: "Bitter",
                      fontWeight: 500,
                      lineHeight: "2.75rem",
                    }}>
                      INTELLIVENT
                    </div>
                  </div>
                  <form autoComplete='new-password'
                    style={{ accentColor: "black" }}
                  >
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
                      <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Name</Form.Label>
                      <Form.Control
                        autoComplete='new-password'
                        type="text"
                        value={name}
                        className='rounded-full'
                        style={textfieldStyle}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
                      <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Phone</Form.Label>
                      <InputGroup className="mb-2">
                        <InputGroup.Text id="basic-addon1" style={{ borderTopLeftRadius: 1000, borderBottomLeftRadius: 1000, }}>+62</InputGroup.Text>
                        <Form.Control
                          autoComplete='new-password'
                          type="number"
                          value={phone}
                          className=''
                          style={{
                            borderTopRightRadius: 1000,
                            borderBottomRightRadius: 1000,
                          }}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </InputGroup>

                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
                      <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Email</Form.Label>
                      <Form.Control
                        autoComplete='new-password'
                        type="text"
                        value={email}
                        className='rounded-full'
                        style={textfieldStyle}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
                      <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Password</Form.Label>
                      <Form.Control
                        autoComplete='new-password'
                        type="password"
                        value={password}
                        className='rounded-full'
                        style={textfieldStyle}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
                      <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Confirm Password</Form.Label>
                      <Form.Control
                        autoComplete='new-password'
                        type="password"
                        value={confirmPassword}
                        className='rounded-full'
                        style={textfieldStyle}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{ display: "flex", flexDirection: "column" }}>
                      <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Date of Birth</Form.Label>
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        style={textfieldStyle}
                        popupStyle={{ zIndex: 10000 }}
                        value={dob}
                        onChange={(date) => setDob(date)}
                        /* panelRender={(panelNode) => {createPortal(panelNode, document.body)}} */
                        getPopupContainer={triggeredNode => {
                          createPortal(triggeredNode, /* document.getElementById("fullscreenElement") */ document.body)
                          console.log(triggeredNode)
                        }}
                      />
                    </Form.Group>
                    <dialog>a</dialog>
                    {/* {createPortal()} */}
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
                      <label style={{ fontSize: "0.8em", paddingLeft: "1.2em", marginBottom: "0.5em" }}>Gender</label>
                      <div className="flex items-start flex-col mb-2">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input id="default-checkbox"
                            name="gender"
                            type="radio"
                            value="MALE"
                            onClick={
                              (e) => {
                                console.log(e.target.value)
                                setGender(e.target.value)
                              }}
                            className="accent-intelBlack w-4 h-4 outline-none text-intelOrange bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          /* checked={checked} */
                          />
                          <label htmlFor="default-checkbox" style={radiolabelStyle}>Male</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input id="default-checkbox"
                            name="gender"
                            type="radio"
                            value="FEMALE"
                            onClick={
                              (e) => {
                                console.log(e.target.value)
                                setGender(e.target.value)
                              }}
                            className="accent-intelBlack w-4 h-4 outline-none text-intelOrange bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          /* checked={checked} */
                          />
                          <label htmlFor="default-checkbox" style={radiolabelStyle}>Female</label>
                        </div>
                      </div>
                    </Form.Group>
                  </form>
                  <Button onClick={() => {
                    handleSubmit()
                  }}
                    style={{
                      width: "100%",
                      borderRadius: "0.375rem",
                      background: "#000",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                      display: "flex",
                      padding: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.25rem",
                      alignSelf: "stretch",
                    }}>Submit</Button>
                  <Button style={{
                    width: "100%",
                    borderRadius: "0.375rem",
                    background: "var(--primary-200, #CE93D8)",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    padding: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.25rem",
                    alignSelf: "stretch",
                  }}
                    onClick={() => handleClose()}>Cancel</Button>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </div >
    </>
  )
}