import {

  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, message, Radio } from 'antd';
import { da } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import User from 'src/api/user';
import dayjs from 'dayjs'
import { Form, InputGroup, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UserModalAdd = (props) => {
  const { open, data, onClose, mode, isNewRecord } = props;
  console.log('hubla', data)
  const [id, setId] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [dob, setDob] = useState();
  const [gender, setGender] = useState(null);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const clearData = () => {
    setDob()
    setGender(null)
    setName()
    setPhone()
    setPassword()
    setEmail()
    setConfirmPassword()
  }

  const handleClose = (isRefresh = true) => {
    clearData()
    onClose(isRefresh)
  }

  useEffect(() => {
    if (open) {
      setId(data?.id);
      setAvatarUrl(data?.avatarUrl);
      setName(data?.name);
      setGender(data?.gender);
      setEmail(data?.email);
      setPhone(data?.phone_num);
      setDob(data?.dob);
      console.log('hublaxx', data)
    } else {
      clearData()
    }
  }, [data])

  const submit = async () => {

    if (!name) {
      message.error('Field Name is required')
      return
    }

    if (!email) {
      message.error('Field Email is required')
      return
    }

    if (!gender) {
      message.error('Field Gender is required')
      return
    }

    if (!dob) {
      message.error('Field DOB is required')
      return
    }

    if (!phone) {
      message.error('Field Phone Number is required')
      return
    }

    let body = {
      name: name,
      gender: gender,
      email: email,
      avatarUrl: avatarUrl,
      dob: dob,
      phone_num: "62"+phone,
    }
    try {
      let res
      if (!data || data.length <= 0) {
        res = await User.create(body);
        if (res.uuid) {
          message.success('Successfully add user')
        } else {
          message.error('Failed update user')
        }
        handleClose(true);
        return;
      }
      res = await User.edit(id, body);
      console.log(res)
      if (res.success) {
        message.success('Successfully update user')
      } else {
        message.error('Failed update user')
      }
      handleClose(true);
      return;
    } catch (e) {
      message.error(e.error_message ? e.error_message : 'Failed update user')
      return
    }
  }

  return (

    <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form autoComplete="new-password">

          <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Name</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Please input name"
              value={name}
            />
          </Form.Group>
          <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>E-Mail</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Please input email"
              value={email}
            />
          </Form.Group>
          <Form.Group autoComplete="new-password" className="mb-3 d-block" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Date of Birth</Form.Label>
            <DatePicker disabledDate={(current) => {
              return current && current >= dayjs().startOf('day');
            }} format={'DD/MM/YYYY'} style={{ width: '100%' }} value={dob ? dayjs(dob) : dob}
              onChange={(date) => setDob(date)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Gender</Form.Label>
            <Radio.Group value={gender} style={{ width: '100%' }} onChange={(e) => {
              setGender(e.target.value);
            }}>
              <Radio value={"MALE"}>Male</Radio>
              <Radio value={"FEMALE"}>Female</Radio>
            </Radio.Group>
          </Form.Group>
          <Form.Group autoComplete="new-password" className="mb-3">
            <Form.Label style={{ fontSize: "0.8em" }}>Phone Number</Form.Label>
            <InputGroup>
              <InputGroup.Text>+62</InputGroup.Text>
              <Form.Control
                autoComplete={"new-password"}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="Phone Number"
                value={phone}
              />
            </InputGroup>
          </Form.Group>
          {
            isNewRecord &&
            <>
              <Form.Group autoComplete="new-password" className="mb-3">
                <Form.Label style={{ fontSize: "0.8em" }}>Password</Form.Label>
                <Form.Control
                  autoComplete={"new-password"}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Please input Password"
                  value={password}
                />
              </Form.Group>

              <Form.Group autoComplete="new-password" className="mb-3">
                <Form.Label style={{ fontSize: "0.8em" }}>Confirm Password</Form.Label>
                <Form.Control
                  autoComplete={"new-password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Please Confirm Password"
                  value={confirmPassword}
                />
              </Form.Group>
            </>

          }

          <div className={"d-flex flex-row justify-content-end"}>
            <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <LoadingButton className='ml-3' size="sm" onClick={async () => { await submit() }} variant="primary">
              {isNewRecord ? 'Create' : 'Save'}
            </LoadingButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>

  )
}

export default UserModalAdd;