import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    DialogContent,
    DialogActions,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import moment from "moment";
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import { QrReader } from 'react-qr-reader';
import { message } from 'antd';
import dayjs from 'dayjs'
import UserTagModal from "../components/custom/UserTagModal";
import Portal from 'react-overlays/Portal'
import UserTagModalV2 from "../components/custom/UserTagModalV2";
import SignupModal from 'src/components/custom/SignupModal';
import { useNavigate } from 'react-router-dom';
import UserTagModalDemo from 'src/components/custom/UserTagModalDemo';

const QRKioskV2 = () => {

    const NOTFOUND = 0,
        SUCCESS = 1,
        ERROR = -1

    const FC_USER_MODE = 'user',
        FC_ENVIRONTMENT = 'environment'
    const [openDialog, setOpenDialog] = useState(false)
    const [isOpenSignupModal, setIsOpenSignupModal] = useState(false)

    const [scanStatus, setScanStatus] = useState(0)
    const [scanMessage, setScanMessage] = useState(null)
    const [resultScan, setResultScan] = useState()
    const [facingMode, setFacingMode] = useState(FC_USER_MODE)

    const [qrData, setQRData] = useState(null)
    const [loading, setIsLoading] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const navigate = useNavigate()
    const handleResult = async (qrData) => {
        
        try {
            let result = await User.scanAction(qrData.text)
            if (result?.name) {
                if (currentId !== result?.uuid) {
                    // setScanStatus(SUCCESS)
                    setQRData({
                        name: result.name,
                        code: result.uuid,
                        title: result.title
                    })
                    setCurrentId(result.uuid)
                }
            
            } else {
                setScanStatus(NOTFOUND)
            }
            setCurrentId(null)
            setResultScan(result)
            setIsLoading(false)
        } catch (e) {
            setCurrentId(null)
            setIsLoading(false)
            setScanStatus(ERROR)
        }


    }

    // const handeMessage = () => {
    //     let obj = {}
    //     if (scanStatus === SUCCESS) {
    //         obj = {
    //             title: 'Success',
    //             color: 'green',
    //             message: 'Checkin successfully'
    //         }
    //     } else if (scanStatus === NOTFOUND) {
    //         obj = {
    //             title: 'Not Found',
    //             color: 'yellow',
    //             message: 'User not found'
    //         }
    //     } else {
    //         obj = {
    //             title: 'Error',
    //             color: 'red',
    //             message: "Invalid QR"
    //         }
    //     }
    //     return obj
    // }

    return (

        <div id="fullscreenElement">
            <QrReader
                scanDelay={100}
                onResult={(result, error) => {
                    console.log('handling result', result)
                    if (!!result) {
                        handleResult(result)
                        // setOpenDialog(true)
                    }

                    if (!!error) {

                    }

                }}
                ViewFinder={() => {
                    return (
                        <>
                            {/*<div className={isFullscreen ? 'crosshair-full' : 'crosshair'}></div>*/}
                        </>
                    )
                }}
                constraints={{ facingMode: facingMode }}
                style={{
                    /* marginTop: "-10vh" */
                }}
                videoStyle={{ maxWidth: '100vw', height: "100vh", objectFit: "cover", position: "fixed" }}
            />
            <div style={{
                maxHeight: "100vh",
                maxWidth: "100vw",
                position: "fixed",
                zIndex: 20,
                top: 0,
                left: 0
            }}
            >
                <div style={{
                    background: "#00000080",
                    height: "35vh",
                    width: "100vw"
                }}>

                </div>
                <div
                    className={"d-flex flex-row"}
                    style={{
                        // background : "#00000080",
                        height: "30vh",
                        width: "100vw"
                    }}>
                    <div style={{
                        flex: 1,
                        background: "#00000080",
                    }}>

                    </div>
                    <div style={{
                        height: "100%",
                        width: "30vh",
                        border: "7px solid black"
                    }}>

                    </div>
                    <div style={{
                        flex: 1,
                        background: "#00000080",
                    }}>

                    </div>
                </div>
                <div
                    className={"d-flex flex-column align-items-center justify-content-start"}
                    style={{
                        background: "#00000080",
                        height: "35vh",
                        width: "100vw",
                        paddingRight: "2vw",
                        paddingLeft: "2vw",
                        paddingTop: 20
                    }}>

                    <div style={{ color: "white", textAlign: "center" }}>
                        Welcome to Intellivent Test Kit. <br />
                        Please scan your QR here.
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <div style={{ marginTop: "1em", color: "white" }}>Don't have an account?</div>
                        <Button variant={"contained"} onClick={() => setIsOpenSignupModal(true)}>
                            Click here to sign up
                        </Button>
                        <Button className='mt-4' variant='contained' onClick={() => navigate("/qr-ir-scanner")}>
                            Use IR scanner
                        </Button>
                    </div>
                </div>
            </div>

            <SignupModal
                isOpen={isOpenSignupModal}
                onClose={() => setIsOpenSignupModal(false)}
                setUuid={uuid => handleResult(uuid)}
            />

            <UserTagModalDemo
                loading={loading}
                data={qrData}
                onClose={() => {
                    setQRData(null)
                }}
            />

        </div>
    )
}

export default QRKioskV2