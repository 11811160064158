import ApiRequest from "../utils/apiRequest"

export default class Admin {
    static login = async (body) => {
        return await ApiRequest.set("v1/admin/login", "POST", body)
    }

    static loginShort = async (body) => {
        return await ApiRequest.set("v1/admin/loginshort", "POST", body)
    }

    static getAll = async () => {
        return await ApiRequest.set("v1/admins", "GET")
    }

    static getById = async (id) => {
        return await ApiRequest.set("v1/admin/"+id, "GET")
    }

    static create = async (body) => {
        return await ApiRequest.set("v1/admin", "POST", body)
    }

    static edit = async (id, body) => {
        return await ApiRequest.set("v1/admin/"+id, "PUT", body)
    }
    static password_reset = async (id, body) => {
        return await ApiRequest.set("v1/admin/"+id + "/password_reset", "PATCH", body)
    }

    static delete = async (id) => {
        return await ApiRequest.set("v1/admin/"+id, "DELETE")
    }

    static restore = async (id) => {
        return await ApiRequest.set("v1/admin/"+id, "PATCH")
    }
}