import ApiRequest from "../utils/apiRequest"

export default class User {
    static login = async (body) => {
        return await ApiRequest.set("v1/user/login", "POST", body)
    }

    static loginShort = async (body) => {
        return await ApiRequest.set("v1/user/loginshort", "POST", body)
    }

    static getAll = async () => {
        return await ApiRequest.set("v1/users", "GET")
    }

    static getById = async (id) => {
        return await ApiRequest.set("v1/user/" + id, "GET")
    }

    static scanAction = async (uuid) => {
        return await ApiRequest.set("v1/qr/scan/" + uuid, "GET")
    }

    static create = async (body) => {
        return await ApiRequest.set("v1/user", "POST", body)
    }

    static edit = async (id, body) => {
        return await ApiRequest.set("v1/user/" + id, "PUT", body)
    }
    static password_reset = async (id, body) => {
        return await ApiRequest.set("v1/user/" + id + "/password_reset", "PATCH", body)
    }

    static delete = async (id) => {
        return await ApiRequest.set("v1/user/" + id, "DELETE")
    }

    static restore = async (id) => {
        return await ApiRequest.set("v1/user/" + id, "PATCH")
    }
    
}
