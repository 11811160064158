import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  DialogContent,
  DialogActions,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import moment from "moment";
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import { QrReader } from 'react-qr-reader';
import { Spin, message } from 'antd';
import dayjs from 'dayjs'
import UserTagModal from "../components/custom/UserTagModal";
import Portal from 'react-overlays/Portal'
import UserTagModalV2 from "../components/custom/UserTagModalV2";
import SignupModal from 'src/components/custom/SignupModal';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const QRScannerWithIR = () => {
  const [qrData, setQRData] = useState(null)
  const [loading, setLoading] = useState(false)

  const fieldRef = useRef(null);
  const [messageAPI, contextHolder] = message.useMessage();
  const navigate = useNavigate()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      let result = await User.scanAction(fieldRef.current.value)
      console.log(result)
      if(result.checked_in) {
        messageAPI.open({
          type: 'error',
          content: 'This card has already been printed'
        })
        handleClose()
        setLoading(false)
        return;
      }
      if (result?.name) {
        // setScanStatus(SUCCESS)
        setQRData({
          name: result.name,
          code: result.uuid,
          title: result.title
        })
      } else {
        messageAPI.open({
          type: 'error',
          content: 'QR is invalid'
        })
      }
    } catch (e) {
      messageAPI.open({
        type: 'error',
        content: 'QR is invalid'
      })
    }
    setLoading(false)
  }

  const handleClose = () => {
    fieldRef.current.value = "";
    fieldRef.current.focus();
  }

  return (
    <div style={{ maxHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center' }}>
      {contextHolder}
      <Spin spinning={loading}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center' }}>
          <h2>How to scan your QR:</h2>
          <div>1. Point the scanner gun 15-30 cm away from the QR code.</div>
          <div>2. Pull the trigger, wait until the gun beeps.</div>
          <div>3. If nothing happens, try moving the gun closer or farther.</div>
          <div>4. Your card will be printed shortly.</div>
          <Button className='mt-4' onClick={() => navigate("/qr-kiosk")} variant='contained'>
            Use kiosk mode
          </Button>
          <Form.Control
            autoFocus
            ref={fieldRef}
            onBlur={e => e.target.focus()}
            onKeyDown={e => {
              console.log(e, fieldRef)
              if (e.key == 'Enter') handleSubmit();
            }}
            className='hide'
          />
        </div>
      </Spin>
      <UserTagModalV2
        data={qrData}
        onClose={() => {
          setQRData(null)
          handleClose()
        }}
        autoPrint
      />
    </div>
  )
}

export default QRScannerWithIR